import { useEffect, useState } from "react";
import LFGForm from "./LFGForm";
import LFGMatch from "./LFGMatch";
import { useNavigate } from "react-router-dom";
import useNotifs from "../hooks/useNotifs";

const LFGbox = () => {
    const { wsRef, refreshChats, incomingMsg, setIncomingMsg, notifs } = useNotifs();
    const [ isSelecting, setIsSelecting ] = useState(true);
    const [ selection, setSelection ] = useState(null)
    const [ isSearching, setIsSearching ] = useState(false);
    const [ match, setMatch ] = useState({});
    const [ skipped, setSkipped ] = useState([]);
    const [ accepted, setAccepted ] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        if(incomingMsg && incomingMsg !== null) {
            if('username' in incomingMsg) {
                setMatch(incomingMsg);
            } else if('accepted' in incomingMsg) {
                setAccepted(1);
            } else if('friended' in incomingMsg) {
                const asyncWrapper = async () => {
                    const isIncomingReq = notifs.find(notif => notif.text === (match.username + " sent you a friend request"));
                    if (isIncomingReq) {
                        notifs.splice(notifs.indexOf(isIncomingReq), 1);
                    }
                    await refreshChats();
                    setIncomingMsg(null);
                    navigate('/chats');
                };
                asyncWrapper();
            } else if('skipped' in incomingMsg) {
                console.log('skipped');
                setIsSearching(true);
            }
        }
        // eslint-disable-next-line
    }, [incomingMsg, navigate, refreshChats, setIncomingMsg]);

    const handleAccept = async () => {
        if(accepted === 1) {
            try {
                wsRef.send(JSON.stringify({ friend: true, friendId: match.userId}));
            } catch (err) {
                console.error(err);
            }
        } else {
            try {
                wsRef.send(JSON.stringify({ accepted: true, friendId: match.userId}));
            } catch (err) {
                console.error(err);
            }
        }
    };

    useEffect(() => {
        const search = () => {
            setIsSearching(true);
            setIsSelecting(false);
            try {
                wsRef.send(JSON.stringify(selection));
            } catch (err) {
                console.error(err);
            }
        };
        if(selection != null) search();
    }, [selection, wsRef]);

    useEffect(() => {
        if(!skipped.includes(match.username)) {
            setIsSearching(false);
        } else {
            wsRef.send(JSON.stringify({ skipped: true, friendId: match.userId}));
            setIsSearching(true);
        }
    }, [match, skipped, wsRef]);

    useEffect(() => {
        return () => {
            console.log('lfg unmounted');
            cancelSearch();
        };
        // eslint-disable-next-line
    }, []);

    const cancelSearch = async () => {
        await wsRef.send(JSON.stringify({ lfgCancel: true }));
        setIsSearching(false);
    };

    return (
        <div>
            {isSelecting 
                ?   <LFGForm setSelection={setSelection} />
                :   isSearching
                        ?   <div>
                                <p className="mt-5">Searching for users who want to play: {selection.game}</p>
                                <button type="button" title="cancel" onClick={cancelSearch}>Cancel</button>
                            </div>
                        :   <LFGMatch 
                                match={match} 
                                handleAccept={handleAccept} 
                                accepted={accepted}
                                setAccepted={setAccepted}
                                setSkipped={setSkipped} 
                                setIsSearching={setIsSearching}
                            />   
            }
        </div>
    );
};

export default LFGbox;