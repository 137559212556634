import { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import "../CSS/AccountForm.css";
import useAuth from "../hooks/useAuth";
import BrandingBar from "../Components/BrandingBar";

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Register = () => {
    const userRef = useRef();
    const errRef = useRef();
    
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const [ username, setUser ] = useState('');
    const [ validName, setValidName ] = useState(false);
    const [ userFocus, setUserFocus ] = useState(false);

    const [ password, setPwd ] = useState('');
    const [ validPwd, setValidPwd ] = useState(false);
    const [ pwdFocus, setPwdFocus ] = useState(false);
    
    const [ matchPwd, setMatchPwd ] = useState('');
    const [ validMatch, setValidMatch ] = useState(false);
    const [ matchFocus, setMatchFocus ] = useState(false);

    const [ errMsg, setErrMsg ] = useState('');

    useEffect(() => {
        userRef.current.focus();
        if(auth?.userId) {
            navigate('/', { replace: true });
        }
    }, [auth?.userId, navigate])

    useEffect(() => {
        const result = USER_REGEX.test(username);
        setValidName(result);
    }, [username])

    useEffect(() => {
        const result = PWD_REGEX.test(password);
        setValidPwd(result);
        const match = password === matchPwd;
        setValidMatch(match);
    }, [password, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [username, password, matchPwd])

    async function handleSubmit(e) {
        e.preventDefault();

        // further form validation
        if(!PWD_REGEX.test(password) || !USER_REGEX.test(username)) {
            setErrMsg("invalid Entry");
            return;
        }

        try {
            const response = await axios.post('/register', 
                {username, password}, 
                {
                    headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                }
            );
            // setting user context vars.
            const accessToken = response?.data?.accessToken;
            const userId = response?.data?.userId;
            setAuth({ userId, accessToken, username });
            navigate(from, { replace: true });
            window.location.reload(false);

        } catch(err) {
            if(!err?.response) {
                console.log(err);
                setErrMsg('No Server Response');
            } else if(err.response?.status === 409) {
                setErrMsg('Username Taken');
            } else {
                setErrMsg('Registration Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <section>
            <BrandingBar />
            <h1 className="text-center" style={{marginTop: "150px"}}>Register</h1>
            <form className="accountform" onSubmit={handleSubmit}>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                <label htmlFor="username">
                    Username:
                    <span className={validName ? "" : "hide"}>✅</span>
                    <span className={validName || !username ? "hide" : ""}>❌</span>
                </label>
                <input 
                    type="text"
                    id="username"
                    placeholder="username"
                    className="p-2"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    required
                    aria-invalid={validName ? "false" : "true"}
                    aria-describedby="uidnote"
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                />
                <p id="uidnote" className={userFocus && username && !validName ? "instructions" : "offscreen"}>
                    ⚠ 4 to 24 characters<br></br>
                    Must begin with a letter<br></br>
                    Letters, numbers, underscores, and hyphens allowed
                </p>


                <label htmlFor="password">
                    Password:
                    <span className={validPwd ? "" : "hide"}>✅</span>
                    <span className={validPwd || !password ? "hide" : ""}>❌</span>
                </label>
                <input 
                    type="password"
                    id="password"
                    placeholder="password"
                    className="p-2"
                    onChange={(e) => setPwd(e.target.value)}
                    required
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                />
                <p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                    ⚠ 8 to 24 characters<br></br>
                    Must include uppercase and lowercase letters, a number, and a special character<br></br>
                    Allowed special characters: <span aria-label="exclamation mark">!</span>
                        <span aria-label="at symbol">@</span> 
                        <span aria-label="hashtag">#</span> 
                        <span aria-label="dollar sign">$</span> 
                        <span aria-label="percent">%</span>
                </p>

                <label htmlFor="confirm_pwd">
                    Confirm Password:
                    <span className={validMatch && matchPwd ? "" : "hide"}> ✅</span>
                    <span className={validMatch || !matchPwd ? "hide" : ""}>❌</span>
                </label>
                <input 
                    type="password"
                    id="confirm_pwd"
                    placeholder="password"
                    className="p-2"
                    onChange={(e) => setMatchPwd(e.target.value)}
                    required
                    aria-invalid={validMatch ? "false" : "true"}
                    aria-describedby="confirmnote"
                    onFocus={() => setMatchFocus(true)}
                    onBlur={() => setMatchFocus(false)}
                />
                <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                    ⚠ Passwords must match
                </p>

                <button disabled={!validName || !validPwd || !validMatch ? true : false}>Sign Up</button>

                <p>
                    Have an account? 
                    <span className="link" >
                        <Link to="/login"> Login</Link>
                    </span>
                </p>
            </form>

        </section>
    );
};

export default Register;